<div class="widthLimiter">
    <h2>National Outage Map (NOM)</h2>
    <p>
        The EAGLE-I™ National Outage Map is a view of the number of customers affected by outages by county, state, and FEMA region
        aggregates. The customer outage data collection process [or extract, translate, and load (ETL)] monitors public electric utility
        customer outage websites and updates every 15 minutes. The information posted on the electric utility outage is highly variable
        between utilities; some utilities report outages by county, some by point, some by zip code, and some by polygons. Sometimes, the
        total number of customers served is provided by the utility. Some outage websites feed automatically from the utility's outage
        management system, and some outage websites are manually updated based on customer outage reports (e.g., by phone).
    </p>

    <p>
        The EAGLE-I™ ETL assumes the utility reported outage data is correct as stated. a utility reports the number of members (common for
        electric cooperatives) or customers without power, the EAGLE-I™ ETL assumes these are electric customers and is consistent with
        customers reported by the utility in the EIA-861 survey. The EAGLE-I™ ETL monitors for unusual outage reporting on the outage
        website.
    </p>

    <p>
        If an electric utility is not included in EAGLE-I™ ETL monitoring, that utility is omitted from EAGLE-I™ data calculations. If a
        county has no covered electric customers because all utilities (one or more) associated with that county are not monitored by
        EAGLE-I™, then that county is omitted from EAGLE-I™ reports and data calculations.
    </p>

    <h2>County Customers</h2>
    <p>
        Total customers by county is an internally modeled number calculated using county population, customer totals from each utility, and
        the coverage area of each utility. County population was derived from the
        <a href=" https://gii.dhs.gov/hifld/data/secure/" target="_blank">LandScan USA</a> data, produced at ORNL and funded by HIFLD, which
        provides high resolution day and night population estimates. Information on utility customer totals was collected from EIA service
        territories, and
        <a href=" https://hifld-geoplatform.opendata.arcgis.com/datasets/c4fd0b01c2544a2f83440dab292f0980_0" target="_blank"
            >Electric Retail Service Territories</a
        >
        geospatial coverage data provided by HIFLD. The total number of customers for each utility was divided among the counties the
        utility covers, weighted by the population of each county.
    </p>
</div>
